import React from "react"
import { Helmet } from "react-helmet"
import "../css/style.css"
import { NavAr } from "./navAr"
import { NavEn } from "./navEn"

interface Props {
  lang: "en" | "ar"
}
export const Header = (props: Props) => {
  const lang = props.lang
  return (
    <div>
      <Helmet bodyAttributes={{ class: `locale-${lang}` }} />
      <header className="header-area">
        <div className="header-menu">
          <div className="navigation">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <nav className="navbar navbar-expand-lg">
                    <div className="mr-auto ml-auto">
                      <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarFive"
                        aria-controls="navbarFive"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <span className="toggler-icon"></span>
                        <span className="toggler-icon"></span>
                        <span className="toggler-icon"></span>
                      </button>
                      {lang == "en" ? <NavEn /> : <NavAr />}
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  )
}
