import { graphql, Link } from "gatsby"
import { flatMap } from "lodash"
import React, { useEffect, useState } from "react"
import { Spinner } from "react-bootstrap"
import CourseCard from "../components/courseCard"
import { Footer } from "../components/Footer"
import { Header } from "../components/Header"
import { subscribesCollectionRef } from "../config/collectionsRef"
import "../config/firebase"
import "../css/all.css"
import "../css/index"
import { useNiceSelectValue } from "../hooks/useNiceSelectValue"
import "../scss/index.scss"
import locales from "../translate/locales"
import makeCourseLocationsInctance from "../utils/makeCourseLocationsInctance"

export const query = graphql`
  query {
    allContentfulCourses {
      edges {
        node {
          id
          title
          language
          introductionVideoLinkYoutubeLink
          isOnline
          price
          startDate
          endDate

          content {
            childMarkdownRemark {
              html
            }
          }

          description {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          totalHours
          coverImage {
            file {
              url
            }
          }

          category {
            category
            arabic
          }

          countriesAndDates {
            location
            start_date
            end_date
            contentful_id
          }
        }
      }
    }

    allContentfulHomePage {
      edges {
        node {
          offerSectionTitleEn
          offerSectionTitleAr
          offerSectionDescriptionEn
          offerSectionDescriptionAr
          communitySectionTitleEn
          communitySectionTitleAr

          trainingAreasSection {
            titleAr
            titleEn
            iconClassName
          }

          servicesSection {
            titleAr
            titleEn
            descriptionAr
            descriptionEn
            iconName
            iconColor
          }

          counterSection {
            titleAr
            titleEn
            number
          }
          communitySectionImage {
            file {
              url
            }
          }
          communitySectionDescriptionEn {
            childMarkdownRemark {
              html
            }
          }

          communitySectionDescriptionAr {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`

const Home = (props: any) => {
  const [subscribe, setSubscribe] = useState("")
  const locale = props.pageContext.locale || "en"
  const allContentfulCourses = props.data.allContentfulCourses.edges

  const pageContent = props.data.allContentfulHomePage.edges[0].node

  const lastCourses = flatMap(
    allContentfulCourses.map((_courese: any) => {
      return makeCourseLocationsInctance(_courese.node)
    })
  )

  const texts = locales[locale as "en"]

  useEffect(() => {
    document.body.parentElement!.className += `locale-${locale}`
  }, [])

  const selectedCategory = useNiceSelectValue("category-select", undefined)
  const selectedCountry = useNiceSelectValue("country-select", undefined)
  const selectedCourseType = useNiceSelectValue("course-type-select", undefined)

  const [searchCourseText, setSearchCourseText] = useState("")

  const [SpinneringIndex, setSpinneringIndex] = useState(false)

  const categories = [
    { value: "managementAndAccounting", text: "management and accounting" },
    { value: "safetyAndSecurity", text: "Safety and Security" },
    { value: "HumanResourceManagement", text: "Human Resource Management" },
    { value: "managementAndLeadership", text: "Management and leadership" },
    { value: "mediaAndPublicRelations", text: "Media and Public Relations" },
    { value: "salesAndMarketing", text: "Sales And Marketing" },
    {
      value: "institutionalExcellenceAndQuality",
      text: "Institutional excellence and quality",
    },
    { value: "lawAndContracts", text: "Law and contracts" },
    { value: "engineeringAndEnergy", text: "Engineering and energy" },
    { value: "universityPrograms", text: "University programs" },
    { value: "informationTechnology", text: "Information Technology" },
    { value: "oilAndGasSector", text: "Oil and gas sector" },
  ]

  return (
    <div>
      <Header lang={locale} />

      <section
        className="banner-area bg_cover mt-80"
        style={{
          backgroundImage: "url(/images/london.png)",
          paddingTop: "10%",
          height: 700,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="banner-content">
                <span> Mastery Academy</span>
                <h1 className="title">
                  {texts.homePageContent.educationAndDevelopment}
                </h1>
                <ul>
                  <li>
                    <a className="main-btn" href={`/${locale}/courses`}>
                      <i
                        className="fal fa-user"
                        style={{ margin: "0 10px" }}
                      ></i>
                      {texts.homePageContent.findACourse}
                    </a>
                  </li>
                  <li>
                    <a
                      className="main-btn white-Background "
                      href={`/${locale}/teacherForm`}
                    >
                      <i
                        className="fal fa-flag"
                        style={{ margin: "0 10px" }}
                      ></i>{" "}
                      {texts.homePageContent.becomeATeacher}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="dream-course-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="dream-course-content">
                <form action="#">
                  <div className="dream-course-search d-flex">
                    <div className="input-box">
                      <i
                        style={{ color: "#2d589a" }}
                        className="fal fa-search"
                      ></i>

                      <input
                        type="text"
                        placeholder={texts.homePageContent.findACourseInput}
                        onChange={(e) => setSearchCourseText(e.target.value)}
                      />
                    </div>

                    <div
                      className="dream-course-category d-lg-inline-block"
                      id="category-select"
                    >
                      <select>
                        <option data-display={texts.categories.category}>
                          {texts.categories.category}
                        </option>
                        <option value="1">
                          {texts.categories.managementAndAccounting}
                        </option>
                        <option value="2">
                          {texts.categories.safetyAndSecurity}
                        </option>
                        <option value="4">
                          {texts.categories.HumanResourceManagement}
                        </option>
                        <option value="5">
                          {texts.categories.managementAndLeadership}
                        </option>
                        <option value="6">
                          {texts.categories.mediaAndPublicRelations}
                        </option>
                        <option value="7">
                          {texts.categories.salesAndMarketing}
                        </option>
                        <option value="8">
                          {texts.categories.institutionalExcellenceAndQuality}
                        </option>
                        <option value="9">
                          {texts.categories.lawAndContracts}
                        </option>
                        <option value="10">
                          {texts.categories.engineeringAndEnergy}
                        </option>
                        <option value="11">
                          {texts.categories.universityPrograms}
                        </option>
                        <option value="12">
                          {texts.categories.informationTechnology}
                        </option>
                        <option value="13">
                          {texts.categories.oilAndGasSector}
                        </option>
                      </select>
                    </div>
                    <div
                      className="dream-course-category  d-lg-inline-block"
                      id="country-select"
                    >
                      <select>
                        <option data-display={texts.countries.country}>
                          {texts.countries.country}{" "}
                        </option>
                        <option value="1">{texts.countries.usa}</option>
                        <option value="2">{texts.countries.malisya}</option>
                        <option value="4">{texts.countries.britain}</option>
                      </select>
                    </div>

                    <div
                      className="dream-course-category  d-lg-inline-block"
                      id="course-type-select"
                    >
                      <select>
                        <option
                          data-display={texts.coursesPageContent.courseType}
                        >
                          {texts.coursesPageContent.courseType}{" "}
                        </option>
                        <option value="1">
                          {texts.coursesPageContent.online}
                        </option>
                        <option value="2">
                          {texts.coursesPageContent.faceToFace}
                        </option>
                      </select>
                    </div>

                    <div className="dream-course-btn">
                      <Link
                        className="findLink"
                        to={`${locale}/courses?course=${searchCourseText}&category=${selectedCategory}&country=${selectedCountry}&at=${selectedCourseType}`}
                      >
                        {texts.homePageContent.findACourse}
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="services-area pb-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-11">
              <div className="section-title text-center">
                <h3 className="title">
                  <span>{texts.homePageContent.trainingAreas}</span>
                </h3>
              </div>
            </div>
          </div>
          <div className="services-border">
            <div className="row no-gutters">
              {pageContent.trainingAreasSection.map((e) => {
                const title = locale == "ar" ? e.titleAr : e.titleEn
                return (
                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <a href={`/${locale}/courses/?category=${title}`}>
                      <div className="single-services item-2 text-center">
                        <i className={e.iconClassName}></i>
                        <h5 className="title">{title}</h5>
                      </div>
                    </a>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </section>

      <section
        className="offer-area bg_cover pt-110 pb-120"
        style={{
          backgroundImage: `linear-gradient(
          rgba(255, 149, 149, 0.27),
          rgb(0, 45, 98)
        ),url(/images/hall-congress-architecture-building-159213.jpeg)`,
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-11">
              <div className="offer-content text-center">
                <span>
                  {locale == "en"
                    ? pageContent.offerSectionTitleEn
                    : pageContent.offerSectionTitleAr}
                </span>
                <h2 className="title">
                  {locale == "en"
                    ? pageContent.offerSectionDescriptionEn
                    : pageContent.offerSectionDescriptionAr}
                </h2>
                <ul>
                  <li>
                    <a className="main-btn" href={`/${locale}/courses`}>
                      <i className="fal fa-user"></i>{" "}
                      {texts.homePageContent.findACourse}
                    </a>
                  </li>
                  <li>
                    <a
                      className="main-btn main-btn-2"
                      href={`/${locale}/teacherForm`}
                    >
                      <i className="fal fa-flag"></i>{" "}
                      {texts.homePageContent.becomeATeacher}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="advance-courses-area pb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="section-title">
                <h3 className="title">
                  {texts.homePageContent.advanceCourses}
                </h3>
              </div>
            </div>
          </div>

          <div className="row courses-active">
            {lastCourses.map((_courese: any, key: number) => {
              if (!_courese) {
                return <></>
              }

              return (
                <CourseCard courseData={_courese} priceStyle={{ bottom: 45 }} />
              )
            })}
          </div>
        </div>
      </section>

      <section
        className="features-area  bg_cover"
        style={{ position: "unset", paddingBottom: 100 }}
      >
        <div className="container-fluid">
          <div className="features-margin pl-70 pr-70">
            <div className="row">
              <div className="col-lg-12">
                <div className="container-fluid">
                  <div className="row">
                    {pageContent.servicesSection.map((e) => {
                      const title = locale == "ar" ? e.titleAr : e.titleEn
                      const description =
                        locale == "ar" ? e.descriptionAr : e.descriptionEn
                      return (
                        <div className="col-lg-6 col-md-6">
                          <div className="single-features mt-30">
                            <div className="icon-contianr">
                              <i
                                className={e.iconName}
                                style={{ fontSize: 48, color: e.iconColor }}
                              ></i>
                            </div>
                            <h4 className="title">{title}</h4>
                            <p>{description}</p>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="counter-area bg_cove"
        style={{
          backgroundImage: `linear-gradient(rgba(255, 149, 149, 0.27), rgb(0, 45, 98)), url("images/pexels-photo-1181344.jpeg")`,
          marginTop: 50,
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7"></div>
          </div>
          <div className="row">
            {pageContent.counterSection.map((e) => {
              const title = locale == "ar" ? e.titleAr : e.titleEn
              const number = e.number

              return (
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="counter-item text-center pt-40">
                    <h3 className="title">
                      <span className="counter">{number}</span>+
                    </h3>
                    <span>{title}</span>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="counter-dot">
          <img src="/images/counter-dot.png" alt="dot" />
        </div>
      </section>

      <section className="community-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="community-content">
                <h3 className="title">
                  {locale == "en"
                    ? pageContent.communitySectionTitleEn
                    : pageContent.communitySectionTitleAr}
                </h3>

                <p>
                  {locale == "en"
                    ? pageContent.communitySectionDescriptionEn.childMarkdownRemark.html.split(
                        /<\/?p>/
                      )
                    : pageContent.communitySectionDescriptionAr.childMarkdownRemark.html.split(
                        /<\/?p>/
                      )}
                </p>

                <div className="input-box">
                  <input
                    style={{ direction: "ltr" }}
                    type="email"
                    placeholder={texts.homePageContent.enterYourEmail}
                    onChange={(e) => setSubscribe(e.target.value)}
                  />
                  <button
                    type="button"
                    style={{ background: "#2d589a" }}
                    onClick={() => {
                      if (subscribe.includes("@")) {
                        setSpinneringIndex(true)
                        subscribesCollectionRef
                          .add({
                            email: subscribe,
                            date: new Date(),
                          })
                          .then(() => {
                            alert(texts.homePageContent.subscribeMsg)
                            setSubscribe("")
                            setSpinneringIndex(false)
                          })
                      }
                    }}
                  >
                    {texts.homePageContent.subscribeNow}

                    {SpinneringIndex ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : null}
                  </button>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <img
                src={pageContent.communitySectionImage.file.url}
                alt="community"
                style={{ borderRadius: 10 }}
              />
            </div>
          </div>
        </div>
      </section>

      <Footer lang={locale} />
    </div>
  )
}

export default Home
