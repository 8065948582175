export default {
  ar: {
    header: {
      home: "الصفحة الرئيسية",
      about: "من نحن",
      blog: "المدونة",
      coachingAcademy: "أكاديمية إعداد المدربين",
      onlineAcademyTraing: "أكاديمية التدريب الالكتروني",
      courses: "الدورات",
      contact: "تواصل معنا",

      online: "اون لاين",
    },

    footer: {
      ourPages: "الصفحات",
      subscribeNow: " إشترك الآن",
      contactInfo: "معلومات الاتصال",
      home: "الصفحة الرئيسية",
      about: "من نحن",
      blog: "المدونة",
      coachingAcademy: "Coaching Academy",
      courses: "الدورات",

      allRightsReserved:
        "جميع حقوق النشر محفوظة 2007 - 2021 | ماستري أكاديمي للتدريب والتطوير Mastery Academy",
      Interested: "مهتم للانضمام إلينا",
      enterEmail: "ادخل بريدك الالكتروني....",
    },

    categories: {
      category: "التصنيفات",
      managementAndAccounting: "الإدارة والمحاسبة",
      safetyAndSecurity: "السلامة والأمن",
      HumanResourceManagement: "ادارة الموارد البشرية",
      managementAndLeadership: "الإدارة والقياد",
      mediaAndPublicRelations: "وسائل الإعلام والعلاقات العامة",
      salesAndMarketing: "المبيعات والتسويق",
      institutionalExcellenceAndQuality: "التميز المؤسسي والجودة",
      lawAndContracts: "القانون والعقود",
      engineeringAndEnergy: "الهندسة والطاقة",
      universityPrograms: "برامج الجامعة",
      informationTechnology: "تكنولوجيا المعلومات",
      oilAndGasSector: "قطاع النفط والغاز",
    },

    countries: {
      country: "البلد",
      usa: "الولايات المتحدة ",
      malisya: "ماليزيا",
      britain: "بريطانيا",
    },

    counterSection: {
      trainingProgram: "برنامج تدريب",
      trainingDiploma: "دبلوم التدريب",
      trainingArea: "مجال التدريب",
      student: "طالب علم",
    },

    loginPage: {
      email: "الايميل",
      password: "كلمةالمرور",
      login: "تسجيل الدخول",
    },

    homePageContent: {
      educationAndDevelopment: "التعليم والتنمية",
      findACourseInput: "ادخل كلمة مفتاحية",

      findACourse: "ابحث عن دورة",
      trainingAreas: "مجالات التدريب",
      becomeATeacher: "انضم كمدرب",
      email: "البريد الالكتروني *",

      findYourDreamCourse: "ابحث عن الدورة المفضلة لديك",
      offerReady: "هل أنت مستعد لهذا العرض",
      offer: "عرض 50٪ لأول 50 طالبًا ومعلمًا.",
      advanceCourses: "الدورات المتقدمة",
      ProfessionalTraining: "تدريب احترافي",
      ProfessionalTrainingContent:
        "التدريب المهني هو الخدمة الرئيسية لأكاديمية ماستر ، حيث أنه متخصص في تدريب القادة الإداريين والمهنيين في مختلف التخصصات. لأنها تعتقد أن تطوير القيادة ينعكس في نتائج المنظمة بأكملها",
      developmentProjects: "مشاريع التنمية",
      developmentProjectsContent:
        "التنمية هي عملية مستمرة ودائمة على مستوى المؤسسة. توفر Master Academy خدمات التطوير الإداري في مختلف القطاعات ، مثل الاستشارات والمشورة الشخصية ، فضلاً عن برامج القياس والتقييم المختلفة.",
      trainingBags: "حقائب التدريب",
      trainingBagsContent:
        "لدى Masteri فريق من الخبراء والكفاءات المتخصصة في تطوير حزم التدريب للبرامج الحضرية والإلكترونية في مختلف المجالات التي تخدم احتياجات التدريب في القطاعين الحكومي والخاص.",
      onlineTraining: "التدريب الالكتوني",
      onlineTrainingContent:
        "تقدم الماجستير خدمات لتطوير منصات التدريب الإلكترونية للقطاع الحكومي بما يتماشى مع التطور الكبير في هذا القطاع ، ولديه عدد من التجارب الناجحة والمثمرة في مختلف المجالات للحكومة والقطاع الخاص",
      startGrowingTitle: "البدء في النمو مع",
      TheCommunity: "المجتمع",
      startGrowingContent:
        "خدمتنا مجانية للمستخدمين لأن البائعين يدفعون لنا عندما يتلقون زيارات الويب. نحن ندرج جميع البائعين - وليس فقط أولئك الذين يدفعون لنا - في دليلنا الشامل بحيث يمكنك مقارنة نتائجك وفرزها وتصفيتها لجعل القرار الأكثر استنارة ممكنًا. GetApp هي شركة Gartner. تعتبر Gartner (NYSE: IT) رائدة أبحاث تكنولوجيا المعلومات في العالم",
      subscribeNow: "إشترك الآن",
      enterYourEmail: "أدخل بريدك الإلكتروني ....",
      subscribeMsg: "تم اشتراكك بنجاح شكرا لك للأشتراكك لنا ",
    },
    aboutPageContent: {
      aboutUs: "من نحن",
      home: "الصفحة الرئيسية",
      about: "من نحن",
      introContent: ` تأسست إتقان أكاديمية التدريب والتطوير في عام 2007
        في المملكة المتحدة - المملكة المتحدة وفروعها في الأردن 2010 وماليزيا
      2017 توسعت للتقدم أعمالها وخدماتها في
        الشرق الأوسط وجنوب آسيا. يتم توفير ماستر أكاديمي
      للقيادة العليا وخدمات التدريب الإداري و
      الوسط في القطاعين الحكومي والخاص.`,

      introContentMore: `  كما أطلقت أكاديمية التدريب الإلكتروني في أوائل عام 2018 إلى
      تقديم خدماتها التدريبية Mail في جميع أنحاء العالم. ال
      توفر أكاديمية أكاديمية التدريب خدمة مخصصة عبر الإنترنت
      للشركات والجهات الحكومية. لرؤية الأكاديميةy`,

      professionalAcademy: `ماجستير في الأكاديمية المهنية يحقق الاحتراف من قبل
      تطوير معايير الجودة وأفضل الدولية
      الإمارات في التدريب المهني ، لأنه يبتكر المنتجات
      سنويا لاضافة دورات تدريبية لعملائها
      مصممة على أساس الاحتياجات التدريبية التي يتم تحليلها و
      التي تم تحديدها من خلال التواصل مع العملاء من خلال محددة
      النماذج والأساليب التي تؤدي إلى توفير برامج التدريب
      مع الجودة العالية التي تحقق قيمة المصداقية و
      التزام. نحن نطور أنفسنا ونبني القيم التي
      كل من يعمل في Master Academy يؤمن بتحقيقه
      أعلى رضا لشركائنا في النجاح.`,

      masteryAcademy: "أكاديمية ماستري للتدريب و التطوير ",
      features: "المميزات",
      rateUs: "قيمنا",
      ourTeam: "فريقنا",
      ourServices: "خدماتنا",
      trainingDiplomas: "دبلومات التدريب",
      masteryExpertsAndTeams: "خبراء إتقان وفرق",
      ourMessage: "رسالتنا",
      graduateResearch: "بحوث الدراسات العليا مع",
      offerUs: "عروضنا",
      weOffer:
        "نحن نقدم برامج تدريبية نوعية قائمة على الاحتياجات موجهة نحو مساعدة القادة والمديرين على تحقيق أهدافهم المهنية والوظيفية.",

      ourTeamContentP1: `نسعى للوصول إلى الشهادات والتوصيات الدولية. تعمل Master Academy مع أفضل الخبراء والمدربين في مختلف المجالات ، وتقدم خدماتها بالتعاون مع المتخصصين والأشخاص المؤهلين تأهيلا عاليا والخبرة المهنية العالية في مجال التدريب والاستشارات. نحن نوظف أيضًا فقط أولئك الذين يمكنهم إرضاء عملائنا وتزويدهم بخدمة تفوق التوقعات.`,
      ourTeamContentP2: `يعمل فريقنا دائمًا على الابتسام والموظفين المتفائلين الذين يرون النجاح من وجهة نظر العميل. هذه حقيقة وليست مبالغة. حاول أن تتواصل معهم يومًا ما. نحن الأفضل في تدريب وتطوير القيادات الإدارية والفنية في القطاعين الحكومي والخاص.`,
      ProfessionalTraining: "تدريب احترافي",
      developmentProjects: "مشاريع التنمية",
      onlineTraining: "تدريب على الأنترنت",
      trainingBags: "حقائب التدريب",

      trainingDiplomasP1: `تتميز أكاديمية إتقان للتدريب والتطوير بقدرتها على توفير التدريب النوعي الذي يجمع بين جودة التدريب الرأسي والأفقي. بالإضافة إلى برامج التدريب القصيرة ، تقدم Masteri شهادات تدريب وبرامج متخصصة لمدة 10 أيام تدريب ، بحيث يمكن للمشارك أن يكون على دراية بالمهارات الشاملة في مجال الدورة (التدريب على الجودة الأفقية) ، بالإضافة إلى الدخول بعمق في نقل التفاصيل ،`,
      trainingDiplomasP2: `المهارات والأدوات العملية في مجال الدورة (التدريب على الجودة الرأسية). توفر Masteri سنويًا عددًا محدودًا من هذه الشهادات ، مما يجعلها أكثر تركيزًا ومهتمًا في توفير الجودة العالية والاهتمام والتطوير لمنتجاتها وخدماتها التعليمية والتدريبية.`,
      trainingDiplomasP3: `تعرف على البرامج التدريبية والدبلومات الآن ولا تفوت فرصة لاحتضان تخصصك وتجربتك مع المحترفين`,
      readMore: "اقرأ المزيد",

      masteryExpertsAndTeamsP1: `تعمل Master Academy مع أفضل الخبراء والمدربين في مختلف المجالات وتقدم خدماتها بالتعاون مع متخصصين وذوي مؤهلات عالية وخبرة مهنية عالية في مجال التدريب والاستشارات.`,
      masteryExpertsAndTeamsP2: `نحن نوظف أيضًا فقط أولئك الذين يمكنهم إرضاء عملائنا وتزويدهم بخدمة تفوق التوقعات. يعمل فريقنا دائمًا على الابتسام والموظفين المتفائلين الذين يرون النجاح من وجهة نظر العميل. هذه حقيقة وليست مبالغة. حاول أن تتواصل معهم يومًا ما.`,
      becomeATeacher: "انضم كمدرب",
      rateUsContent: `تحقق ماستري أكاديمي الاحترافية المهنية من خلال تطوير معايير الجودة وأفضل الممارات العالمية في التدريب الاحترافي، فهي تبتكر في المنتجات سنوياً لتضيف لعملائها دورات تدريبية يتم تصميمها بناءاً على الاحتياجات التدريبية التي يتم تحليلها وتحديدها بالتواصل مع العميل من خلال نماذج وطرق محددة تقود إلى تقديم برامج تدريبية ذات جودة مرتفعة يتحقق فيها قيمة المصداقية والالتزام.
      نطور أنفسنا ونبني قيم يؤمن بها كل من يعمل في ماستري أكاديمي لنحقق أعلى رضا لشركائنا في النجاح.`,
    },

    coursesPageContent: {
      latestCourse: "الدورات",
      home: "الصفحة الرئيسية",
      courses: "الدورات",
      searchYourCourse: "بحث الدورة المفضلة لديك",
      year: "السنة",
      month: "الشهر",
      online: "اون لاين",
      faceToFace: "حضوري",
      courseType: "نوع الدورة",
    },
    coachingAcademyPageContent: {
      whatIsACoachingAcademy: "ما هي أكاديمية إعداد المدربين?",
      introP1: `سست ماستري أكاديمي مشروعها الرائد أكاديمية إعداد المدربين Training Trainers Academy TTA في عام 2010م، لتكون واحدة من المشاريع المتخصصة في إعداد وتدريب المدربين، وقد خرجت الأكاديمية 13 دفعة منذ تأسيسها في كل من السودان وليبيا والأردن والبحرين والسعودية وسوريا وتركيا، والغرض الرئيسي من تأسيس الأكاديمية هو إعداد جيل من المدربين المتميزين وبمقاييس عالية تمكنهم من تأدية وتوصيل رسالتهم إلى الأمة بشكل إحترافي وإبداعي مساهمين بذلك في نهضة العالم العربي والإسلامي.`,
      introP2: `وطورت الأكاديمية نموذجاً خاصاً لها يدعى BTD شمل معظم الأسس والقواعد والمهارات الرئيسية في علم التدريب ليكون منهجاً شمولياً لإعداد المدرب بشكل إحترافي متكامل يركز على فن الإلقاء وقوة التأثير ، وكذلك مهارات التدريب لصناعة القدرات الفاعلة، بالإضافة إلى منهج التخطيط للتدريب الذي يركز على العمليات ذات الأهمية العالية والتي تسبق التدريب مثل تحليل الإحتياجات التدريبية وإعداد الأهداف وتطوير المنهج والحقيبة التدريبية.`,
      academicModel: "نموذج الأكاديمية",
      academicModelContent:
        "تم تصميم نموذج البرنامج ليغطي العملية التدريبية والأدوار والممارسات المهنية للمدرب المحترف، وتحتفظ أكاديمية ماستري للتدريب والتطوير بحقوق هذا النموذج تحت مسمى (أبعاد التدريب المتوازنة Balanced Training Dimensions BTD).",

      targetGroup: "الفئة المستهدفة",
      targetGroupContent: `تم تصميم منهج الأكاديمية ليلبي احتياج المدربين الجدد والمدربين ذوي الخبرة وكذلك الراغبين في إمتهان التدريب للمؤسسات والمنظمات الحكومية والخاصة وغير الربحية.`,

      coachPerformanceRecord: "سجل أداء المدرب",
      coachPerformanceRecordContent: `يحفظ لكل مشارك سجل أداء Performance Profile PP خاص به يتضمن نشاط المشارك خلال الأكاديمية وفترة التدريب، كما يتضمن جميع الملاحظات والعلامات التي دونت له من قبل لجنة التقييم خلال العروض التي قدمها في الدورة.`,
      askedQuestions: "أسئلة شائعة",
      questionOne: "ماهي الطريقة للتعاقد مع أكاديمية إعداد المدربين ؟",
      questionOneContent: `يمكن لأي منظمة حكومية أو خاصة التعاقد مع أكاديمية إعداد المدربين لتنفيذها بشكل حصري وخاص لموظفي الجهة سواءاً كانت حكومية أو خاصة، وللحصول على عرض فني ومالي يرجى التواصل مع فريق المبيعات عبر البريد الالكتروني `,

      questionTow: `كيف يمكن لمشارك فرد أن يحصل على شهادة مدرب محترف معتمد ؟`,
      questionTowContent: `هناك طريقتين للحصول على الشهادة وحضور الأكاديمية للأفراد، الطريقة الأولى من خلال الاشتراك في الأكاديمية حضورياً في حال تم الإعلان عنها، والطريقة الثانية من خلال الحصول على الدورة أونلاين في`,

      questionThree: `كيف يمكن الاطلاع على مزايا الخريجين والدفعات السابقة ؟`,
      questionThreeContent: `من خلال زيارتك لموقع مشروع أكاديمية إعداد المدربين تستطيع الاطلاع على الدفعات السابقة وكذلك مزايا التخرج من الأكاديمية وكل التفاصيل الخاصة بالمنهج التدريبي`,

      ourSponsors: "من عملاء أكاديمية تدريب المدربين",
      goToSit: `انتقل لموقع أكاديمية إعداد المدربين`,
    },

    onlineAcademyPageContent: {
      onlineTraining: " أكاديمية التدريب الإلكتروني",
      onlineAcademyTraing: "أكاديمية التدريب الإلكتروني",
      WhatIsAnEtrainingAcademy: "ما هي أكاديمية التدريب الإلكتروني",
      introP1: `أطلقت ماستري أكاديمي مشروعها المتخصص في التدريب الالكتروني من خلال منصة تدريب الكتروني تستهدف رواد الأعمال وأصحاب المشاريع الصغيرة والمتوسطة، لتساهم بذلك في رفع مهارات ومعرفة الأفراد المبادرين وأصحاب المشاريع الصغيرة والمتوسطة في تطوير أعمالهم من خلال التدريب الاحترافي الالكتروني وبأقل التكاليف الممكنة`,
      introP2: `اقى المشروع نجاحاً متميزاً مما دفع عدد من الجهات والقطاعات الحكومية والخاصة بالتعاقد مع الأكديمية للاستفادة من خبرتها في هذا المجال ومساعدتهم في تطوير منصات تدريب الكتروني وتصميم الحقائب التدريبية الالكترونية. `,
      onlineCoursesModel: "الدورات الالكترونية",
      onlineCoursesModelContent:
        "قدمت ماستري أكاديمي عدد من الدورات الالكترونية الموجهة للأفراد من خلال منصتها المستقلة، وتم تصميم منتهج الاكاديمية لتلبي احتياجات رواد الأعمال وأصحاب المشاريع الصغيرة والمتوسطة.",

      building: "بناء منصات التدريب الاكتروني",
      buildingContent: `بعد النجاح في تأسيس منصة التدريب الالكتروني بادرت ماستري بتلبية احتياجات القطاع الحكومي والخاص في تأسيس منصات تدريب الكتروني وفق أحدث أنظمة التعليم والتدريب الالكتروني LMS.`,

      buildingBags: "بناء الحقائب الالكترونية",
      buildingBagsContent: `كما طورت ماستري أكاديمي طريقة فريدة ومتميزة لنباء حقائب التدريب الالكتروني لتلبية احتياجات الأكاديميات الخاصة بالمنظمات الحكومية والخاصة، من مرحلة الاعداد إلى التصوير والانتاج وتطوير المنهج.`,
      askedQuestions: "أسئلة شائعة",
      questionOne:
        "هل تقدم الأكاديمية خدمة بناء منصة تدريب الكتروني للقطاع الحكومي أو الخاص ؟",
      questionOneContent: `من خلال فريق تقني مختص تقدم أكاديمية ماستري خدمة بناء منصة تدريب الكتروني متكاملة الجوانب فيما يتعلق بالعملية التعليمية للمنظمات الحكومية والخاصة، ويتم تسليم الأكاديمية جاهزة للتشغيل وقابلة للتعديل في جميع جوانبها مع خدمة الدعم الفني وفقاً لاحتياج الجهة.`,

      questionTow: `في حال وجود أكاديمية لدينا هل يمكن تطوير حقائب تدريبية في تخصصات محددة ؟`,
      questionTowContent: `طورت الأكاديمية عشرات الحقائب التدريبية لعدد من عملائها في القطاع الحكومي والخاص، ولذلك لدى الأكاديمية خبرة متميزة في هذا المجال، وتتم عملية بناء الحقيبة وفقاً لعدة مراحل ابتداءاً من دراسة احتياج الفئة المستهدفة ومروراً بعمليات اختيار المدربين والتصوير وتطوير المناهج وانتهاءاً بعملية تسليم الحقيبة جاهزة لرفعها في منصة العميل الخاصة به. مع الحفاظ على انتقال كامل الحقوق الملكية للعميل.`,

      questionThree: `كيف يمكن للأفراد الاستفادة من الدورات الالكترونية في أكاديمية ماستري ؟`,
      questionThreeContent: `يسعدنا اطلاعكم على دوراتنا الحالية في منصة التدريب الالكتروني الخاصة بنا والتي تجدونها عبر الرابط التالي `,

      ourSponsors: "من عملاء أكاديمية التدريب الإلكتروني",
      goToSit: `انتقل إلى منصة التدريب الالكتروني`,
    },

    blogPageContent: {
      blog: "المدونة",
      news: "أخر المقالات",
      search: "ابحث",
      searchKeyWord: "ابحث في كلمتك الرئيسية ...",
      newsFeeds: "اخر المقالات ",
      neverMissNews: "لا تفوت أخر المقالات",
      home: "الصفحة الرئيسية",
    },

    contactPage: {
      contactUs: "تواصل معنا",
      Home: "الصفحة الرئيسية",
      contact: "التواصل",
      info: "معلومات",
      contactInfoIntro: `نحن سعدين جدا لتواصك معنا اهلا بك في فريق Master Academy.`,
      number: "رقم الهاتف",
      email: "الايميل",
      address: "عنوان المكتب",
      collToAction: "ارسل رسالة",
      enterEmail: "ادخل الايميل ",
      enterName: "ادخل الاسم",
      enterMassage: "الرسالة",
      Submit: "ارسال",
      telephone: "الهاتف",
      checkYourCertificate: "تأكد من شهادتك",
      enterYourCertificateNumber: "ادخل  رقم الشهادة...",
      telphone: "رقم الهاتف الارضي  ",
      yourContactMsg: "تم ارسال بنجاح",
      iCantFoundMyCertificate: "لا يمكنني العثور على شهادتي!",
    },

    becomeATeacherForm: {
      welcomeToOurTeam: "مرحبا بكم في فرقنا",
      intro: ` تسعى أكاديمية ماستر لتوسيع نطاق الخبرة و
        الكفاءات التي تعمل معها لتزويد العملاء بها
      أفضل النتائج والممارسات في مرحلة التسليم. نحن
      شكرا لك على اهتمامك وتعاونك ونرحب بكم
      كمدرب محترف في Master Academy`,
      email: "البريد الالكتروني",
      name: "الاسم ",
      LastName: "الكنية ",
      Nationality: "الجنسية",
      countryOfResidence: " بلد الإقامة ",
      city: "مدينتك",
      mobile: "رقم الهاتف المحمول",
      dateOfBirth: "تاريخ الولادة",
      degree: " آخر درجة حصلت عليها ",
      generalSpecialization: " التخصص العام ",
      specialization: "تخصص ",
      mainAreas: "مجالات التدريب الرئيسية التي تمارسها ",
      phd: "Ph.D.",

      ma: "M.A.",
      ba: "BA",
      yearsOfExperience: " سنوات من الخبرة كمدرب محترف ",
      noTraining: "لا خبرة تدريبية ",
      years: "سنوات",
      sectorTraining: "معظم خبرتك التدريبية كانت في مجال التدريب",
      governmentSector: "القطاع الحكومي",
      privateSector: "القطاع الخاص",
      charitableSector: "القطاع الخيري",
      practiceProfessionalLang: `اختر اللغة التي تتحدثها بطلاقة `,
      ar: "العربية",
      en: "الانكليزية",
      fr: "الفرنسية",
      additionalServices: `ما هي الخدمات الإضافية التي تقدمها بالإضافة إلى
      الخدمة التدريبية داخل القاعة ولديك خبرة لا تقل عن سنتين `,
      trainingBags: "إعداد الحقائب التدريبية",
      onlineTraining: "تدريب على الأنترنت",
      trainingNeedsAnalysis: "تحليل الاحتياجات التدريبية",
      consulting: "مستشار",
      personalGuidance: "التوجيه الشخصي",
      cv: `يرجى إرفاق ملف السيرة الذاتية `,
      Submit: "إرسال",
      sub:
        "اشترك في النشرة الإخبارية لدينا للحصول على أحدث العروض والدورات التدريبية المتاحة",
    },

    certificatesSearchPage: {
      noResult: "لا يوجد نتيجة رجاء",
      certificates: "الشهادات",
      check: "تأكد",
      contactUs: "تواصل معنا",
    },

    coursePage: {
      Introduction: "المقدمة",
      Objectives: "الأهداف",
      Audience: "الجمهور",
      Content: "المحتوى",
      similarCourse: "دورات مماثلة",
      thisCourseIncludes: "هذه الدورة تشمل",
      enrollNow: "سجل الان",
      hour: "ساعة",
      day: "يوم",
      ThisCourseIncludes: "هذه الدورة تشمل",
      downloadPdf: "تنزيل ملف ال Pdf الخاص بالدورة",
      downloadCalendar: "تنزيل التقويم الخاص بالدورة",
    },

    enrollCourse: {
      EnrollForm: "التسجيل في هذه الدورة",
      name: "الاسم ",
      lastName: "الكنية ",
      whereYouWork: "اين تعمل ",
      email: "البريد الالكتروني ",
      number: "رقم الهاتف ",
      landlineNumber: "رقم الهاتف الثابت",
      followUp: "هل ترغب بالمتابعة مع إدارة التدريب لديكم ؟",
      nameOfPerson: "اسم المعني في إدارة التدريب",
      trainingAdministrationNo: "رقم إدارة التدريب.",
      theOfficialMobileNumber: "رقم موبايل المسؤول",
      courseInformationTo:
        "هل لديك رغبة في تغييرمعلومات الدورة بما يتناسب معك؟",
      chooseAnotherDate: "اختيار تاريخ آخر للدورة",
      SuggestAnewNumberOfDays: "اقتراح عدد أيام جديد",
      city: "اختيار مدينة جديدة",
      addNewCourseThemes: "اضافة محاور جديدة للدورة",
      joinOurNewsletter:
        "هل تريد الانضمام لنشرتنا البريدية للحصول على آخر الأخبار والعروض?",
      cancel: "الغاء",
      Enroll: "تسجيل",
      yes: "نعم",
      no: "لا",
    },
  },

  en: {
    header: {
      home: "Home",
      about: "About",
      blog: "Blog",
      coachingAcademy: "Coaching Academy",
      courses: "Courses",
      teacherForm: "TeacherForm",
      contact: "Contact Us",
      online: "online",
    },

    footer: {
      ourPages: "Our Pages",
      subscribeNow: " Subscribe Now",
      contactInfo: "Contact Info",
      home: "Home",
      about: "About",
      blog: "Blog",
      coachingAcademy: "Coaching Academy",
      courses: "Courses",
      teacherForm: "TeacherForm",
      allRightsReserved:
        "All rights reserved 2007 - 2021 | Mastery Academy for Training and Development",
      Interested: "Interested To Join Our Class",
      enterEmail: "Enter Your Email...",
    },

    categories: {
      category: "Category",
      managementAndAccounting: "management and accounting",
      safetyAndSecurity: "Safety and Security",
      HumanResourceManagement: "Human Resource Management",
      managementAndLeadership: "Management and leadership",
      mediaAndPublicRelations: "Media and Public Relations",
      salesAndMarketing: "Sales And Marketing",
      institutionalExcellenceAndQuality: "Institutional excellence and quality",
      lawAndContracts: "Law and contracts",
      engineeringAndEnergy: "Engineering and energy",
      universityPrograms: "University programs",
      informationTechnology: "Information Technology",
      oilAndGasSector: "Oil and gas sector",
    },

    countries: {
      country: "Country",
      usa: "USA",
      malisya: "Malisya",
      britain: "Britain",
    },

    counterSection: {
      trainingProgram: "Training Program",
      trainingDiploma: "Training diploma",
      trainingArea: "Training area",
      student: "Student",
    },

    loginPage: {
      email: "Email ",
      password: "Password",
      login: "Login",
    },

    certificatesSearchPage: {
      certificates: "Certificates",
      noResult: "No result found please",
      check: "Check",
      contactUs: "Contact Us",
    },

    homePageContent: {
      educationAndDevelopment: "Education and development",
      findACourse: "Find A Course",
      findACourseInput: "Search Keyword..",
      trainingAreas: "Training areas",
      becomeATeacher: "Become A Teacher",
      findYourDreamCourse: "Find Your Dream Course",
      offerReady: "Are You Ready For This Offer",
      offer: "50% Offer For Very First 50 Students & Mentors.",
      advanceCourses: "Advance Courses",
      ProfessionalTraining: "Professional training",
      ProfessionalTrainingContent:
        "Professional training is the main service for Master Academy, as it specializes in training administrative and professional leaders in various disciplines. Because she believes that leadership development is reflected in the results of the entire organization",
      developmentProjects: "Development projects",
      developmentProjectsContent:
        "Development is a continuous and permanent process at the enterprise level. Master Academy provides administrative development services in various sectors, such as consulting and personal advice, as well as various measurement and evaluation programs.",
      trainingBags: "Training bags",
      trainingBagsContent:
        "Masteri has a team of experts and competencies specialized in developing training packages for urban and electronic programs in various fields that serve training needs in the government and private sector.",
      onlineTraining: "Online training",
      onlineTrainingContent:
        "Masters provides services to develop electronic training platforms for the government sector in line with the great development in this sector, and has a number of successful and fruitful experiences in various fields for the government and private sector",
      startGrowingTitle: "Start Growing With",
      TheCommunity: "The Community",
      startGrowingContent:
        "Our service is free to users because vendors pay us when they receive web traffic. We list all vendors - not just those that pay us - in our comprehensive directory so that you can compare, sort and filter your results to make the most informed decision possible. GetApp is a Gartner company. Gartner (NYSE: IT) is the worlds leading information technology research",
      subscribeNow: "Subscribe Now",
      enterYourEmail: "Enter Your Email.....",
      subscribeMsg:
        "You have been subscribed successfully. Thank you for subscribing to us ",
    },
    aboutPageContent: {
      aboutUs: "About Us",
      home: "Home",
      about: "About",
      introContent: ` Mastery Academy Training was founded and Development in 2007
      in the UK - UK, And its branches in Jordan 2010 and Malaysia
      2017 expanded to progress Its business and services are in
      the Middle East and South Asia. Is provided Master Academy
      for senior leadership and management training services And
      the middle in the governmental and private sectors.`,

      introContentMore: `  She also launched an academy E-training in early 2018 to
      provide its training services   Mail around the world. The
      Academy provides Customized Online service Training Academy
      for companies and government agencies. To see academy`,

      professionalAcademy: ` Master Professional Academy achieves professionalism by
      developing quality standards and the best international
      emirates in professional training, as it innovates products
      annually to add to its clients training courses that are
      designed based on training needs that are analyzed and
      identified by communicating with the customer through specific
      models and methods that lead to providing training programs
      with High quality that achieves the value of credibility and
      commitment. We develop ourselves and build values that
      everyone who works at Master Academy believes in to achieve
      the highest satisfaction for our partners in success.`,

      masteryAcademy: "Mastery Academy",
      features: "Features",
      rateUs: "Rate Us",
      ourTeam: "Our team",
      ourServices: "Our services",
      trainingDiplomas: "Training diplomas",
      masteryExpertsAndTeams: "Mastery Experts and Teams",
      ourMessage: "Our message",
      graduateResearch: "Graduate Research With",
      offerUs: "Us Offers.",
      weOffer:
        "We offer qualitative needs-based training programs geared towards helping leaders and managers achieve their career and career goals.",

      ourTeamContentP1: `We seek to reach international certificates and recommendations Master Academy works with the best experts and trainers in various fields and provides its services in cooperation with specialists and highly qualified people and high professional experience in the field of training and consulting. We also employ only those who can please our customers and provide them with a service that exceeds expectations.`,
      ourTeamContentP2: ` Our team are always smiling and optimistic employees who see success from the customer’s eyes. This is a fact, not an overstatement. Try to get in touch with them one day. We are the best in training and developing administrative and technical leaders in the government and private sector.`,
      ProfessionalTraining: "Professional training",
      developmentProjects: "Development projects",
      onlineTraining: "Online training",
      trainingBags: "Training bags",

      trainingDiplomasP1: `Mastery Academy for Training and Development was distinguished by its ability to provide qualitative training that combines the quality of vertical and horizontal training. In addition to short training programs, Masteri offers training diplomas and specialized programs of 10 training days, so that the participant can be aware of the comprehensive skills in the field of the course (horizontal quality training), as well as Entering deeply we transfer details,`,
      trainingDiplomasP2: `skills and practical tools in the field of the course (vertical quality training). Masteri annually provides a limited number of these diplomas, which makes them more focused and interested in providing high quality, attention and development to their educational and training products and services.`,
      trainingDiplomasP3: `Learn about training programs and diplomas now and never miss an opportunity to embrace your specialty and experience with professionals`,
      readMore: "Read More",

      masteryExpertsAndTeamsP1: `Master Academy works with the best experts and trainers in various fields and provides its services in cooperation with specialists and highly qualified people and high professional experience in the field of training and consulting.`,
      masteryExpertsAndTeamsP2: `We also employ only those who can please our customers and provide them with a service that exceeds expectations. Our team are always smiling and optimistic employees who see success from the customer’s eyes. This is a fact, not an overstatement. Try to get in touch with them one day.`,
      becomeATeacher: "Become A Teacher",
      rateUsContent: `Master Professional Academy achieves professionalism by developing quality standards and the best international emirates in professional training, as it innovates products annually to add to its clients training courses that are designed based on training needs that are analyzed and identified by communicating with the customer through specific models and methods that lead to providing training programs with High quality that achieves the value of credibility and commitment.

      We develop ourselves and build values that everyone who works at Master Academy believes in to achieve the highest satisfaction for our success partners.`,
    },

    coursesPageContent: {
      latestCourse: "Latest Course",
      home: "Home",
      courses: "Course",
      searchYourCourse: "Search Your Course",
      year: "Year",
      month: "Month",
      online: "Online",
      faceToFace: "Face To Face",
      courseType: "Type",
    },
    coachingAcademyPageContent: {
      whatIsACoachingAcademy: "What is a coaching academy?",
      introP1: `Master Academy established its flagship project, the Training Trainers Academy TTA in 2010, to be one of the projects specialized in preparing and training the trainers. The Academy has graduated 13 batches since its establishment in Sudan, Libya, Jordan, Bahrain, Saudi Arabia, Syria and Turkey, and the main purpose of establishing the Academy is Preparing a generation of distinguished trainers with high standards that enable them to perform and deliver their message to the nation in a professional and creative way, contributing to the renaissance of the Arab and Islamic world.`,
      introP2: `The Academy has developed a special model for it called BTD that included most of the foundations, rules and skills in training science to be a holistic approach to preparing the trainer in an integrated professional manner that focuses on the art of speech and impact strength, as well as training skills to manufacture effective capabilities, in addition to a training planning approach that focuses on high-importance operations Which precedes training such as analyzing training needs, preparing goals, developing curriculum and training bag.`,
      academicModel: "Academic model",
      academicModelContent:
        "The program template is designed to cover the training process, roles and professional practices of a professional coach, and Masteri Training and Development Academy reserves the rights of this model under the name (Balanced Training Dimensions BTD).",

      targetGroup: "Target group",
      targetGroupContent: `The curriculum of the Academy is designed to meet the needs of new and experienced trainers as well as those who wish to pursue training for governmental, private and non-profit institutions and organizations.`,

      coachPerformanceRecord: "Coach Performance Record",
      coachPerformanceRecordContent: `Each participant shall maintain his own Performance Profile PP record that includes the participant’s activity during the academy and the training period, and includes all the notes and marks taken by the evaluation committee during his presentations at the course.`,
      askedQuestions: "Asked Questions",
      questionOne:
        "What is the way to contract with the Training of Trainers Academy?",
      questionOneContent: `Any government or private organization can contract an academy to prepare trainers to implement it exclusively and specifically for the entity’s employees, whether governmental or private, and to obtain a technical and financial proposal please contact the sales team via email`,

      questionTow: `How can an individual participant obtain a certified professional trainer certification?`,
      questionTowContent: `Any government or private organization can contract an academy to prepare trainers to implement it exclusively and specifically for the entity’s employees, whether governmental or private, and to obtain a technical and financial proposal please contact the sales team via email`,

      questionThree: `How can I view the benefits of graduates and previous payments?`,
      questionThreeContent: `site, you can view the previous batches, as well as the advantages of graduating from the academy and all the details of the training curriculum`,

      ourSponsors: `Our Sponsors`,
      goToSit: ` Go to the Coaches Academy website`,
    },

    onlineAcademyPageContent: {
      onlineTraining: "Online Training",
      onlineAcademyTraing: "ONLINE TRAINING ACADEMY",
      WhatIsAnEtrainingAcademy: "What is an e-training academy?",
      introP1: `Master Academy launched its project specialized in electronic training through an electronic training platform targeting entrepreneurs and owners of small and medium enterprises, thereby contributing to raising the skills and knowledge of entrepreneurs and owners of small and medium enterprises in developing their business through electronic professional training and at the lowest possible costs`,
      introP2: `the project was a distinct success of Paying a number of governmental and private entities and sectors to contract with the academy to benefit from its experience in this field and assist them in developing electronic training platforms and designing electronic training bags.`,
      onlineCoursesModel: "Online courses",
      onlineCoursesModelContent:
        "Master Academy offered a number of e-courses targeted to individuals through its independent platform, and the Academy curriculum was designed to meet the needs of entrepreneurs and owners of small and medium enterprises.",

      building: "Building electronic training",
      buildingContent: `After successfully establishing the electronic training platform, Masteri took the initiative to meet the needs of the government and private sector in establishing electronic training platforms according to the latest electronic education and training systems (LMS).`,

      buildingBags: "Building electronic bags",
      buildingBagsContent: `Master Academy has also developed a unique and distinct method for news of e-training bags to meet the needs of academies for governmental and private organizations, from the stage of preparation to photography, production and curriculum development.`,
      askedQuestions: "Asked Questions",
      questionOne:
        "Does the academy provide a service to build an electronic training platform for the government or private sector?",
      questionOneContent: `Through a specialized technical team, the Mastery Academy provides a service to build an integrated electronic training platform for all aspects of the educational process for governmental and private organizations.`,

      questionTow: `How can I view the benefits of graduates and previous payments?`,
      questionTowContent: `The Academy has developed dozens of training bags for a number of its clients in the government and the private sector, and therefore the Academy has distinct experience in this field, and the process of building the bag is carried out according to several stages starting from studying the needs of the target group and through the processes of selecting trainers, photography and curriculum development and ending with the process of handing the bag ready to be lifted in a platform Its own customer. While maintaining the complete transfer of the client's property rights.`,

      questionThree: `How can individuals benefit from e-courses at Mastery Academy?`,
      questionThreeContent: `We are pleased to inform you about our current courses in our online training platform, which you can find through the following link`,

      ourSponsors: `Our Sponsors`,
      goToSit: `Go to the e-training academy website`,
    },

    blogPageContent: {
      blog: "Blog",
      news: "News",
      search: "Search",
      searchKeyWord: "Search your keyword...",
      newsFeeds: "News Feeds",
      neverMissNews: "Never Miss News",
      home: "Home",
    },

    contactPage: {
      contactUs: "Contact Us",
      Home: "Home",
      contact: "Contact",
      info: "Info",
      contactInfoIntro: `Postgraduate research with us offers the opportunity to
      contribute to our leading edge research.`,
      number: "Phone Number",
      email: "Email Address",
      address: "Office Address",
      collToAction: "Call To Action",
      enterEmail: "Enter Your Email",
      enterName: "Enter Your Name",
      enterMassage: "Enter Your Massage",
      Submit: "Submit",
      telphone: "Telphone Number",
      checkYourCertificate: "Check Your Certificate",
      enterYourCertificateNumber: "Enter certificate number..",
      yourContactMsg: "Sent successfully",
      iCantFoundMyCertificate: "i Can't find My Certificate!",
    },
    coursePage: {
      Introduction: "Introduction",
      Objectives: "Objectives",
      Audience: "Audience",
      Content: "Content",
      similarCourse: "Similar Course",
      enrollNow: " Enroll Right Now",
      hour: "Hour",
      day: "Day",
      ThisCourseIncludes: "This course includes",
      downloadPdf: "Download This Course Pdf File ",
      downloadCalendar: "Download This Course Calendar File ",
    },
    enrollCourse: {
      EnrollForm: " Enroll In This Course Form",
      name: "Name ",
      lastName: "Last Name ",
      whereYouWork: "Where you work ",
      email: "E-mail ",
      number: "Telephone number ",
      landlineNumber: "Landline Number",
      followUp: "Do you want to follow up with your training department?",
      nameOfPerson: " The name of the person in the training department",
      trainingAdministrationNo: "Training Administration No.",
      theOfficialMobileNumber: "The official mobile number",
      courseInformationTo:
        "Do you want to change course information to suit you?",
      chooseAnotherDate: "Choose another date for the course",
      SuggestAnewNumberOfDays: "Suggest a new number of days",
      city: "Choose A New City",
      addNewCourseThemes: "Add new themes to the course",
      joinOurNewsletter:
        "Want to join our newsletter to get the latest news and offers?",
      cancel: "Cancel",
      Enroll: "Enroll",
      yes: "Yes",
      no: "No",
    },

    becomeATeacherForm: {
      welcomeToOurTeam: "Welcome to our teams",
      intro: ` Master Academy seeks to broaden the range of expertise and
        competencies that it works with to provide customers with
        the best results and practices in the delivery phase. We
        thank you for your interest and cooperation and welcome you
        as a professional coach with Master Academy`,
      email: "Email",

      name: "Name",
      LastName: "Last Name",
      Nationality: "Nationality",
      countryOfResidence: " Country of residence",
      city: "Your City",
      mobile: "Mobile Number",
      dateOfBirth: "Date of Birth",
      degree: " The last degree you got",
      generalSpecialization: "General Specialization",
      specialization: "Specialization",
      mainAreas: "The main areas of training you practice in",
      phd: "Ph.D.",

      ma: "M.A.",
      ba: "BA",
      yearsOfExperience: " Years of experience as a professional coach",
      noTraining: "No training experience",
      years: "Years",
      sectorTraining:
        "Most of your training experience has been in sector training",
      governmentSector: "Government sector",
      privateSector: "private sector",
      charitableSector: "Charitable sector",
      practiceProfessionalLang: `Choose the language in which you practice professional
      fluency *`,
      ar: "Arabic",
      en: "English",
      fr: "French language",
      additionalServices: ` What are the additional services you provide in addition to
      the training service inside the hall and you have experience
      of not less than two years in it`,
      trainingBags: "Preparation of training bags",
      onlineTraining: "Online training",
      trainingNeedsAnalysis: "Training needs analysis",
      consulting: "Consulting",
      personalGuidance: "Personal guidance",
      cv: ` Please attach the CV file `,
      Submit: "Submit",
      sub:
        "Subscribe to our newsletter to get the latest offers and courses available",
    },
  },
}
