import firebase from "./firebase"

export const usersCollectionRef = firebase.firestore().collection("portalUsers")
export const coursesRef = firebase.firestore().collection("courses")
export const certificatesRef = firebase.firestore().collection("certificates")
export const settingsCollectionRef = firebase.firestore().collection("settings")
export const enrollFormsCollectionRef = firebase
  .firestore()
  .collection("enrollForms")

export const teacherFormsCollectionRef = firebase
  .firestore()
  .collection("teacherForms")

export const subscribesCollectionRef = firebase
  .firestore()
  .collection("subscribes")

export const contactFormCollectionRef = firebase
  .firestore()
  .collection("contactForm")
