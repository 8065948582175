import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/storage"

const firebaseConfig = {
  apiKey: "AIzaSyB4GF5jz2uIxYTKzx6_6YEpf6zTnL2cqqw",
  authDomain: "mastery-academy-f8223.firebaseapp.com",
  databaseURL: "https://mastery-academy-f8223.firebaseio.com",
  projectId: "mastery-academy-f8223",
  storageBucket: "mastery-academy-f8223.appspot.com",
  messagingSenderId: "1096994235842",
  appId: "1:1096994235842:web:0a847a4807784097af625b",
  measurementId: "G-RCZEM4E493",
}

firebase.initializeApp(firebaseConfig)

export default firebase
