import { truncate } from "lodash"
import moment from "moment"
import React from "react"
import locales from "../translate/locales"

interface Props {
  courseData: any
  className?: string
  priceStyle?: React.CSSProperties | undefined
}

export default function CourseCard(props: Props) {
  const { courseData, className, priceStyle } = props
  const lang = courseData.language
  const translate = locales[lang as "en"].coursesPageContent
  const textAlign = lang === "ar" ? "right" : "left"

  const description = truncate(
    courseData.description.childMarkdownRemark.rawMarkdownBody,
    {
      length: 80,
      separator: " ",
    }
  )

  return (
    <div className={className ?? "col-lg-4 col-md-6 col-sm-8"}>
      <a href={`/${courseData.language}/course/${courseData._navigateId}`}>
        <div
          className="single-courses mt-30"
          style={{ flexDirection: "row-reverse" }}
        >
          <div className="courses-thumb">
            <img src={courseData.coverImage.file.url} alt="courses" />
            <div className="courses-review">
              <span></span>
            </div>
            <div
              className="corses-thumb-title item-3"
              style={{ [lang == "ar" ? "left" : "right"]: 30 }}
            >
              <span>
                {courseData.language == "en"
                  ? courseData.category.category
                  : courseData.category.arabic}
              </span>
            </div>
          </div>
          <div className="courses-content">
            <h4 className="title" style={{ textAlign }}>
              {courseData.title}
            </h4>
            <p style={{ textAlign }}>{description}</p>
            <div
              className="courses-info d-flex justify-content-between"
              style={{
                [lang == "ar" ? "left" : "right"]: 50,
                ...(priceStyle ?? {}),
              }}
            >
              <span>${courseData.price}</span>
            </div>
            <ul>
              {courseData.isOnline ? (
                <li style={{ margin: 0 }}>
                  <i
                    style={{ margin: "0px 4px" }}
                    className="far fa-play-circle"
                  ></i>{" "}
                  {translate.online}
                </li>
              ) : (
                <li style={{ margin: 0 }}>
                  <i
                    style={{ margin: "0px 4px" }}
                    className="fas fa-map-marker-alt"
                  ></i>{" "}
                  {courseData.location}
                </li>
              )}
              <li>
                <i
                  style={{ margin: "0px 4px" }}
                  className="fal fa-calendar-alt"
                ></i>
                {moment(courseData.startDate).toDate().toLocaleDateString()}
              </li>
              {courseData.totalHours && (
                <li>
                  <i style={{ margin: "0px 4px" }} className="fal fa-clock"></i>
                  {courseData.totalHours}
                </li>
              )}
            </ul>
          </div>
        </div>
      </a>
    </div>
  )
}
