import { useState, useLayoutEffect } from "react"

export function useNiceSelectValue(id: string, initialState: any) {
  const [value, setValue] = useState(initialState)
  useLayoutEffect(() => {
    const timeout = 50
    const fn = () => {
      const categoryNiceSelect =
        document.getElementById(id)?.getElementsByClassName("nice-select")[0] ??
        null

      if (!categoryNiceSelect) {
        setTimeout(fn, timeout)
      }

      setValue(
        categoryNiceSelect?.getElementsByClassName(`current`)[0].textContent
      )

      var observer = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
          if (mutation.attributeName === "class") {
            const newValue = categoryNiceSelect?.getElementsByClassName(
              `current`
            )[0].textContent
            setValue(newValue)
          }
        })
      })

      if (categoryNiceSelect !== null) {
        observer.observe(categoryNiceSelect, {
          attributes: true,
        })
      }
    }

    setTimeout(fn, timeout)
  }, [])

  return value
}
