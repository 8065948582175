import { Link } from "gatsby"
import React from "react"
import "../css/style.css"
// import "../css/bootstrap.ltr.min.css"

export const NavEn = () => {
  const active = { color: "#fff" }
  return (
    <div className="collapse navbar-collapse sub-menu-bar" id="navbarFive">
      <ul className="navbar-nav ">
        <li className={`nav-item`}>
          <Link className="page-scroll" to="/en/" activeStyle={active}>
            Home
          </Link>
        </li>
        <li className={`nav-item`}>
          <Link className="page-scroll" to="/en/about/" activeStyle={active}>
            About
          </Link>
        </li>

        <li className="nav-item">
          <Link className="page-scroll" to="/en/courses" activeStyle={active}>
            Courses <i className="fal fa-angle-down"></i>
          </Link>
          <ul className="sub-menu">
            <li>
              <span>
                <Link to="/en/courses?at=online">online</Link>
              </span>
            </li>
            <li>
              <span>
                <Link to="/en/courses?at=face-to-face">Face to face</Link>
              </span>
            </li>
          </ul>
        </li>

        <li className="nav-item">
          <Link className="page-scroll" to="/en/courses" activeStyle={active}>
            Academies <i className="fal fa-angle-down"></i>
          </Link>
          <ul className="sub-menu">
            <li>
              <Link
                className="page-scroll"
                to="/en/coachingAcademy"
                activeStyle={active}
              >
                coaching Academy
              </Link>
            </li>
            <li>
              <Link
                className="page-scroll"
                to="/en/onlineAcademy"
                activeStyle={active}
              >
                Online Training Academy
              </Link>
            </li>
          </ul>
        </li>

        <li className={`nav-item`}>
          <Link className="page-scroll" to="/en/blog" activeStyle={active}>
            Blog
          </Link>
        </li>
        <li className={`nav-item`}>
          <Link className="page-scroll" to="/en/contact" activeStyle={active}>
            Contact
          </Link>
        </li>

        <li className={`nav-item`}>
          <Link
            className="page-scroll"
            to="/ar"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            العربية{" "}
            <img
              src="/images/sa.svg"
              width="20"
              height="20"
              style={{ margin: "0px 6px" }}
            />
          </Link>
        </li>
      </ul>

      <div className="navbar-nav ">
        <a href="./">
          <img
            className="pageLogo"
            src="/images/logo-wide-04-02.png"
            height="80"
          />
        </a>
      </div>
    </div>
  )
}
