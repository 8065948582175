import "./all.css"
import "./animate.css"
import "./animate.min.css"
import "./certificate-Create.css"
import "./course-create.css"
import "./default.css"
import "./login.css"
import "./magnific-popup.css"
import "./nice-select.css"
import "./owl.carousel.min.css"
import "./pages_styles.css"
import "./searchPage.css"
import "./slick.css"
