import { graphql, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import { Spinner } from "react-bootstrap"
import "../css/style.css"
import useScript from "../hooks/useScript"
import locales from "../translate/locales"

interface Props {
  lang: "en" | "ar"
}

export const Footer = (props: Props) => {
  useScript("/js/vendor/modernizr-3.6.0.min.js")
  useScript("/js/vendor/jquery-1.12.4.min.js")
  useScript("/js/bootstrap.min.js")
  useScript("/js/popper.min.js")
  useScript("/js/slick.min.js")
  useScript("/js/isotope.pkgd.min.js")
  useScript("/js/imagesloaded.pkgd.min.js")
  useScript("/js/owl.carousel.min.js")
  useScript("/js/jquery.nice-select.min.js")
  useScript("/js/wow.min.js")
  useScript("/js/jquery.counterup.min.js")
  useScript("/js/waypoints.min.js")
  useScript("/js/jquery.magnific-popup.min.js")
  useScript("/js/ajax-contact.js")
  useScript("/js/main.js")

  const locale = props.lang
  const texts = locales[locale as "en"]
  const [subscribeFooter, setSubscribeFooter] = useState("")
  const [Spinnering, setSpinnering] = useState(false)

  const contactInfoQuery = useStaticQuery(graphql`
    query {
      allContentfulFooterContactInfo {
        edges {
          node {
            address
            phoneNumber
            whastappNumber
            email
          }
        }
      }
    }
  `)
  const contactInfo =
    contactInfoQuery.allContentfulFooterContactInfo.edges[0].node

  const icon =
    locale === "ar" ? (
      <i style={{ marginLeft: 4 }} className="fal fa-angle-left"></i>
    ) : (
      <i className="fal fa-angle-right"></i>
    )

  return (
    <footer
      className="footer-area footer-area-2"
      style={{
        height: 550,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        paddingTop: 100,
        backgroundImage: `url(/images/footer-5.jpg)`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-7">
            <div className="footer-item mt-30">
              <div className="footer-content">
                <h3 className="title">
                  {texts.contactPage.checkYourCertificate}
                </h3>
                <form action="#">
                  <div className="input-box">
                    <input
                      type="text"
                      placeholder={texts.contactPage.enterYourCertificateNumber}
                      style={{ paddingRight: props.lang == "ar" ? 65 : 10 }}
                      onChange={(e) => setSubscribeFooter(e.target.value)}
                    />
                    <i
                      style={{ color: "#FFF" }}
                      className="fas fa-user-graduate"
                    ></i>
                  </div>
                  <button
                    type="button"
                    style={{ backgroundColor: "#34c392", color: "#FFF" }}
                    onClick={() => {
                      window.location.href = `/${locale}/contact/?se=true&number=${subscribeFooter}`
                    }}
                  >
                    <i
                      style={{ margin: "0px 4px" }}
                      className="fas fa-check"
                    ></i>
                    {texts.certificatesSearchPage.check}
                    {Spinnering ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : null}
                  </button>
                </form>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-7">
            <div
              className="footer-item mt-30"
              style={{ paddingLeft: props.lang == "ar" ? "22%" : 0 }}
            >
              <div className="footer-title item-2">
                <h4 className="title">{texts.footer.ourPages}</h4>
              </div>
              <div className="footer-list-area">
                <div className="footer-list justify-content-between  d-block d-sm-flex">
                  <ul>
                    <li>
                      <a href={`/${locale}/`}>
                        {icon}
                        {texts.header.home}
                      </a>
                    </li>

                    <li>
                      <a href={`/${locale}/about/`}>
                        {icon}
                        {texts.header.about}
                      </a>
                    </li>
                    <li>
                      <a href={`/${locale}/courses`}>
                        {icon}
                        {texts.header.courses}
                      </a>
                    </li>

                    <li>
                      <a href={`/${locale}/blog`}>
                        {icon}
                        {texts.header.contact}
                      </a>
                    </li>
                    <li>
                      <a href={`/${locale}/contact`}>
                        {icon} {texts.header.blog}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div
              className="footer-item mt-30 "
              style={{ paddingLeft: props.lang == "ar" ? "22%" : 0 }}
            >
              <div className="footer-title item-3">
                <h4 className="title">{texts.footer.contactInfo}</h4>
              </div>
              <ul className="contactInfo">
                <li>
                  <i className="fas fa-home"></i> {contactInfo.address}
                </li>
                <li>
                  <i className="fas fa-phone"></i> {contactInfo.phoneNumber}
                </li>
                <li>
                  <i className="fab fa-whatsapp"></i>{" "}
                  {contactInfo.whastappNumber}
                </li>

                <li>
                  <i style={{ marginLeft: 4 }} className="far fa-envelope"></i>
                  {contactInfo.email}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="reserveFooter">
        <span style={{ color: "#FFF" }}>{texts.footer.allRightsReserved}</span>
      </div>
    </footer>
  )
}
