export default function makeCourseLocationsInctance(course: any) {
  if (!course) {
    return
  }

  if (course.isOnline || !course.countriesAndDates) {
    return [
      {
        ...course,
        _navigateId: `${course.id}`,
      },
    ]
  }

  return course.countriesAndDates.map((_data: any) => {
    return {
      ...course,
      ..._data,
      _navigateId: `${course.id}${_data.contentful_id}`,
    }
  })
}
