import { Link } from "gatsby"
import React from "react"
import "../css/style.css"

export const NavAr = () => {
  const active = { color: "#fff" }

  return (
    <div className="collapse navbar-collapse sub-maru-bar" id="navbarFive">
      <ul className="navbar-nav ">
        <div className="navbar-nav ">
          <a href="/ar/">
            <img
              className="pageLogo"
              src="/images/logo-wide-04-02.png"
              height="80"
            />
          </a>
        </div>
        <li className={`nav-item`}>
          <Link className="page-scroll" to="/ar/" activeStyle={active}>
            الرئيسية
          </Link>
        </li>
        <li className={`nav-item`}>
          <Link className="page-scroll" to="/ar/about/" activeStyle={active}>
            من نحن
          </Link>
        </li>

        <li className="nav-item">
          <Link className="page-scroll" to="/ar/courses" activeStyle={active}>
            الكورسات <i className="fal fa-angle-down"></i>
          </Link>
          <ul className="sub-menu" style={{ left: -170 }}>
            <li>
              <span>
                <Link to="/ar/courses?at=online">اون لاين</Link>
              </span>
            </li>
            <li>
              <span>
                <Link to="/ar/courses?at=face-to-face">حضوري</Link>
              </span>
            </li>
          </ul>
        </li>

        <li className="nav-item">
          <Link className="page-scroll" to="/ar/courses" activeStyle={active}>
            الأكادميات <i className="fal fa-angle-down"></i>
          </Link>
          <ul className="sub-menu" style={{ left: -170 }}>
            <li>
              <span>
                <Link className="page-scroll" to="/ar/coachingAcademy">
                  أكاديمية إعداد المدربين
                </Link>
              </span>
            </li>
            <li>
              <span>
                <Link className="page-scroll" to="/ar/onlineAcademy">
                  أكاديمية التدريب الالكتروني
                </Link>
              </span>
            </li>
          </ul>
        </li>

        <li className={`nav-item`}>
          <Link className="page-scroll" to="/ar/blog" activeStyle={active}>
            المدونة
          </Link>
        </li>
        <li className={`nav-item`}>
          <Link className="page-scroll" to="/ar/contact" activeStyle={active}>
            تواصل معنا
          </Link>
        </li>

        <li className={`nav-item`}>
          <Link
            className="page-scroll"
            to="/en"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            English
            <img
              src="/images/united-kingdom.svg"
              width="16"
              height="16"
              style={{ margin: "0px 6px", marginBottom: 4 }}
            />
          </Link>
        </li>
      </ul>
    </div>
  )
}
